const axios = require('axios').default;


// eslint-disable-next-line no-unused-vars
class RecipeRequest {
    constructor() {
    }

    async list () {
        return await axios.get('/recipes');
    }

    async get(recipeId) {
        return await axios.get('/recipe', {
            params: {
                recipeId: recipeId
            }
        });
    }

    async update(recipe) {
        return await axios.put('/recipe', recipe);
    }

    async create(recipe) {
        return await axios.post('/recipe', recipe);
    }
}

export default new RecipeRequest();