<template>
  <v-list dense nav>
    <v-list-item link :to="page.path" v-for="page in pages" :key="page.title">
      <v-list-item-icon>
        <v-icon>{{page.icon}}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{page.title}}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  name: "LeftNavigation",
  props: [],

  data: function() {
    return {
        pages: [
            {
                title: 'Home',
                path: '/',
                icon: 'mdi-home'
            },
                        {
                title: 'My Recipes',
                path: '/recipes',
                icon: 'mdi-book-open-variant'
            },
                        {
                title: 'Brewing',
                path: '/brewing',
                icon: 'mdi-pot-steam'
            },
                        {
                title: 'Fermenting',
                path: '/fermenting',
                icon: 'mdi-clock-time-nine'
            },
                        {
                title: 'Bottled',
                path: '/bottled',
                icon: 'mdi-bottle-wine'
            },
        ]
    };
  },
  mounted() {},
  methods: {
    navigate(path) {
      this.$router.push(path);
    },
  },
};
</script>
