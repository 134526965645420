const axios = require('axios').default;
// eslint-disable-next-line no-unused-vars
class BatchRequest {
    constructor() {
    }

    async getBrewing() {
        return await axios.get('/brewing')
        .then((response) => {
            return response.data;
        });
    }

    async startBrewing(recipeId) {
        const batch = {
            recipeId: recipeId
        }
        return axios.post('/brewing', batch).then(() => {
            return true;
        });
    }

    async startFermenting(batchId) {
        const batch = {
            batchId: batchId
        }
        return axios.put('/fermenting', batch).then(() => {
            return true;
        });
    }

    async finishFermenting(batchId) {
        const batch = {
            batchId: batchId
        }
        return axios.put('/bottling', batch).then(() => {
            return true;
        });
    }
    
    async getFermenting() {
        return await axios.get('/fermenting')
        .then((response) => {
            return response.data.items;
        });
    }
          
    async getBottled() {
        return await axios.get('/bottling')
        .then((response) => {
            return response.data.items;
        });
    }

    async updateIngredients(batchId, ingredients) {
        return await axios.put('/ingredients', {batchId, ingredients});
    }


    // async get(batchId) {
    //     return await axios.get('/batch', {
    //         params: {
    //             batchId: batchId
    //         }
    //     });
    // }

    // async update(batch) {
    //     return await axios.put('/batch', batch);
    // }

    // async create(batch) {
    //     return await axios.post('/batch', batch);
    // }
}

export default new BatchRequest();