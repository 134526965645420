<template>
  <v-app id="app">
    <v-app-bar app color="primary" dark clipped-left fixed>
      <v-app-bar-nav-icon v-if="!large && isAuthenticated()" @click.stop="drawer = !drawer" ></v-app-bar-nav-icon>

      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />
      </div>
      <v-toolbar-title>{{app_name}}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="white" v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group color="primary">
            <v-list-item v-if="isAuthenticated()">
              <v-list-item-content>
                <v-list-item-title v-on:click="logout"
                  >Logout</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!isAuthenticated()">
              <v-list-item-content>
                <v-list-item-title v-on:click="login">Login</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" :permanent="large" clipped app v-if="isAuthenticated()">
      <left-navigation></left-navigation>
    </v-navigation-drawer>
    <v-main class="grey lighten-3 pt-0">
      <v-sheet height="100%">
        <v-container fluid :class="{'pa-0': $vuetify.breakpoint.smAndDown, 'pa-2': $vuetify.breakpoint.mdAndUp}">
          <router-view></router-view>
        </v-container>
      </v-sheet>
    </v-main>
  </v-app>
</template>

<script>
import router from "./router";
import UserInfoStore from './app/user-info-store';
import LeftNavigation from './components/LeftNavigation';

export default {
  name: "App",

  components: {LeftNavigation},
  methods: {
    logout: () => {
      router.push("logout");
    },
    login: () => {
      router.push("login");
    },
    isAuthenticated: () => {
      return UserInfoStore.state.loggedIn;
    }
  },
  computed: {
    large() {
      return this.$vuetify.breakpoint.mdAndUp;
    }
  },
  data: () => ({
    drawer: true,
    app_name: process.env.VUE_APP_NAME
  }),
};
</script>
<style>
main {
  margin-top: 64px;
}
</style>
