<template>
  <v-container>
    <v-sheet color="darken-2" class="pa-3" v-if="loading">
      <v-skeleton-loader
        class="mx-auto"
        width="300"
        type="article"
      ></v-skeleton-loader>
    </v-sheet>
    <template v-if="batch">
      <v-dialog
        :fullscreen="$vuetify.breakpoint.mobile"
        v-model="dialog"
        scrollable
        max-width="750"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title prominent dense>{{
              batch.name
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text style="height:100%" class="pa-0">
            <view-recipe :recipe="batch"></view-recipe>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <v-row v-if="!loading">
      <v-col>
        <h3>Bottled</h3>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col>
        <v-data-table
          @click:row="viewBatch"
          :headers="headers"
          :items="batches"
          :items-per-page="15"
          class="elevation-1 list-batches"
        >
          <template v-slot:[`item.startedFermenting`]="{ item }">
            {{ moment(item.startedFermenting) }}
          </template>
          <template v-slot:[`item.startedBrewing`]="{ item }">
            {{ moment(item.startedBrewing) }}
          </template>
          <template v-slot:[`item.startedBottling`]="{ item }">
            {{ moment(item.startedBottling) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BatchRequest from "../api/BatchRequest";
import ViewRecipe from "../components/recipes/ViewRecipe";
import moment from 'moment';

export default {
  name: "Bottled",
  data: function() {
    return {
      batches: [],
      headers: [
        {
          text: "Recipe",
          value: "name",
        },
        {
          text: "Started Brewing",
          value: "startedBrewing",
        },
        {
          text: "Started Fermenting",
          value: "startedFermenting",
        },
        {
          text: "Bottled",
          value: "startedBottling",
        },
      ],
      loading: true,
      batch: {},
      dialog: false,
    };
  },
  methods: {
    moment: function (date) {
      return moment(date).fromNow();
    },
    viewBatch(batch) {
      this.batch = batch;
      this.dialog = true;
    },
  },
  mounted() {
    BatchRequest.getBottled().then((batches) => {
      this.loading = false;
      return batches;
    })
    .then((batches) => {
      batches = batches.map((batch) =>  {
        batch._metadata = {
          Bottled: new Date().getTime(),
          BrewingStarted: new Date().getTime(),
          FermentingStarted: new Date().getTime()
        }
        return batch;
      });
      this.batches = batches;
    });
  },
  components: { ViewRecipe },
};
</script>
<style scoped lang="sass">
.list-batches::v-deep tr 
  cursor: pointer

</style>
