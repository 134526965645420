<template>
  <div>
    <!-- <v-container fluid>
      <v-row justify="space-around">
        <v-col class="pa-0">
          <v-img aspect-ratio="2" src="./assets/default-beer.jpg"></v-img>
        </v-col>
      </v-row>
    </v-container> -->
    <v-container fluid>
      <!-- Recipe Info -->
      <v-row>
        <v-col cols="12" md="12">
          {{ recipe.description }}
        </v-col>
      </v-row>

      <!-- Batch Size -->
      <v-row>
        <v-col cols="12" md="12">
          <div class="text-h6">Batch Size</div>
        </v-col>
        <v-col cols="12" md="3">
          {{ recipe.batchSize }} - {{ recipe.batchSizeUnit }}
        </v-col>
      </v-row>

      <!-- Ingredients -->
      <v-row align="center">
        <v-col><v-divider></v-divider></v-col>

        <v-col cols="12" md="12">
          <div class="text-h6">Ingredients</div>
        </v-col>
      </v-row>
      <view-ingredients :ingredients="recipe.ingredients"></view-ingredients>
    </v-container>
  </div>
</template>
<script>
import ViewIngredients from "./ViewIngredients";

export default {
  name: "ViewRecipe",
  props: ["recipe"],

  data: function() {
    return {
    };
  },
  mounted() {

  },
  methods: {
  },
  components: {ViewIngredients}
};
</script>