/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import ViewRecipes from '@/views/ViewRecipes'
import Brewing from '@/views/Brewing'
import Fermenting from '@/views/Fermenting'
import Bottled from '@/views/Bottled'
import auth from '../app/auth';
import LogoutSuccess from '@/views/LogoutSuccess';
import UserInfoStore from '../app/user-info-store';
import UserInfoApi from '../app/user-info-api';
import ErrorComponent from '@/views/Error';
import axios from 'axios';

const REDIRECT_URI = process.env.VUE_APP_COGNITO_REDIRECT_URI;
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
Vue.use(Router)

let authInterceptor;

function requireAuth(to, from, next) {
  if (!auth.auth.isUserSignedIn()) {
      UserInfoStore.setLoggedIn(false);
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
  } else {
    setRefreshTokenInterceptor(authInterceptor);
    UserInfoApi.getUserInfo().then(response => {
      UserInfoStore.setLoggedIn(true);
      UserInfoStore.setCognitoInfo(response);
      next();
    });
      
  }
}

function setRefreshTokenInterceptor(interceptor) {
  removeRefreshTokenInterceptor(interceptor);
  interceptor = axios.interceptors.request.use(function (config) {
    if (config.baseURL !== API_BASE_URL) {
      return config;
    }
    return auth.auth.getSession()
      .then(() => {
        config.headers['Authorization'] = 'Bearer ' + auth.auth.getSignInUserSession().getAccessToken().jwtToken;
        return config
      });
  }, function (error) {
    return Promise.reject(error);
  });
}
function removeRefreshTokenInterceptor(interceptor){
  axios.interceptors.request.eject(interceptor);
}

export default new Router({
  mode: 'history',
  base: '/',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      beforeEnter: requireAuth
    },
    {
      path: '/recipes',
      name: 'ViewRecipes',
      component: ViewRecipes,
      beforeEnter: requireAuth
    },
    {
      path: '/brewing',
      name: 'Brewing',
      component: Brewing,
      beforeEnter: requireAuth
    },
    {
      path: '/fermenting',
      name: 'Fermenting',
      component: Fermenting,
      beforeEnter: requireAuth
    },
    {
      path: '/bottled',
      name: 'Bottled',
      component: Bottled,
      beforeEnter: requireAuth
    },
    {
      path: '/login', beforeEnter(to, from, next){
        auth.auth.getSession()
          .then(() => {
            UserInfoStore.setLoggedIn(true);
            if (to?.query?.redirect) {
              next(to.query.redirect);
            }
          })
          .catch(() => {
            UserInfoStore.setLoggedOut();
          });
      }
    },
    {
      path: '/login/oauth2/code/cognito', beforeEnter(to, from, next){
        var currUrl = window.location.href;
        auth.auth.parseCognitoWebResponse(currUrl).then(() => {
          next('/');
        });
      }
    },
    {
      path: '/logout', component: LogoutSuccess,  beforeEnter(to, from, next){
        auth.logout();
        removeRefreshTokenInterceptor(authInterceptor);
        UserInfoStore.setLoggedOut();
        next();
      }

    },
    {
      path: '/error', component: ErrorComponent
    }
  ]
})