<template>
    <v-simple-table :ripple="false" v-bind:dense="!editable">
        <tbody>
          <tr v-for="ingredient in ingredients" :key="ingredient.name">
            <td v-if="editable">
              <v-checkbox @change="updateIngredient(ingredient)" v-model="ingredient.completed"></v-checkbox>
            </td>
            <td>
              {{ ingredient.amount }} {{ ingredient.amountUnit }}
            </td>
            <td>
              {{ ingredient.name }}
            </td>
            <td>
              {{ ingredient.use }} {{ingredient.time}}
            </td>
            <td>
              {{ ingredient.notes }}
            </td>
          </tr>
        </tbody>
    </v-simple-table>
</template>
<script>

export default {
    name: 'ViewIngredients',
    props: ["ingredients", "editable"],
    data: function() {
        return{

        }
    },
    methods: {
      updateIngredient () {
        this.$emit('onChange');
      }
    },
}
</script>
<style>
</style>