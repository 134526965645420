<template>
  <v-container>
    
    <v-sheet color="darken-2" class="pa-3" v-if="loading">
      <v-skeleton-loader
        class="mx-auto"
        width="300"
        type="article"
      ></v-skeleton-loader>
    </v-sheet>
    <template v-if="currentRecipe">
      <v-dialog
        :fullscreen="$vuetify.breakpoint.mobile"
        v-model="dialog"
        scrollable
        max-width="750"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title prominent dense>{{
              currentRecipe.name
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text style="height:100%" class="pa-0">
            <view-recipe :recipe="currentRecipe"></view-recipe>
          </v-card-text>
          <v-bottom-navigation color="primary" horizontal>
            <v-btn v-on:click="startBrewing" block>
              <span>Start Brewing</span>
            </v-btn>
          </v-bottom-navigation>
        </v-card>
      </v-dialog>
    </template>
    <v-row v-if="!loading">
      <v-col>
        <create-new-recipe v-on:success="getRecipes()"></create-new-recipe>

        <v-data-table
          @click:row="viewRecipe"
          :headers="headers"
          :items="recipes"
          :items-per-page="15"
          class="elevation-1 list-recipes"
        >
          <template v-slot:[`item.batchSize`]="{ item }">
            {{ item.batchSize }} {{ item.batchSizeUnit || "Gallons" }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import RecipeRequest from "../api/RecipeRequest";
import CreateNewRecipe from "../components/recipes/CreateNewRecipe.vue";
import ViewRecipe from "../components/recipes/ViewRecipe";
import BatchRequest from "../api/BatchRequest";

export default {
  name: "ListUserRecipes",
  data: function() {
    return {
      recipes: {},
      loading: true,
      dialog: false,
      currentRecipe: null,
      headers: [
        {
          text: "Title",
          value: "name"
        },
        {
          text: "Type",
          value: "category"
        },
        {
          text: "Size",
          value: "batchSize"
        }
      ]
    };
  },
  methods: {
    startBrewing() {
      BatchRequest.startBrewing(this.currentRecipe.id).then((result) => {
        if (result) {
          this.$router.push("/brewing");
        }
      });
    },
    getRecipes() {
      this.loading = true;

      return RecipeRequest.list().then((recipes) => {
        this.recipes = recipes.data.items;
        this.loading = false;
      });
    },
    viewRecipe(recipe) {
      this.currentRecipe = recipe;
      this.dialog = true;
    }
  },
  mounted() {
    this.getRecipes();
  },
  components: { CreateNewRecipe, ViewRecipe },
};
</script>
<style scoped lang="sass">
.list-recipes::v-deep tr 
  cursor: pointer

</style>
