<template>
  <v-container>
    <v-sheet color="darken-2" class="pa-3" v-if="loading">
      <v-skeleton-loader
        class="mx-auto"
        width="300"
        type="article"
      ></v-skeleton-loader>
    </v-sheet>
    <template v-if="!loading && batch">
      <v-row>
        <v-col>
          <h3>{{batch.name}}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="11">
          <view-ingredients
            :ingredients="batch.ingredients"
            editable="true"
            v-on:onChange="saveIngredients"
          ></view-ingredients>
        </v-col>
      </v-row>
      <v-bottom-navigation color="primary" horizontal>
        <v-btn v-on:click="startFermenting" block>
          <span>Finish Brewing</span>
        </v-btn>
      </v-bottom-navigation>
    </template>
  </v-container>
</template>
<script>
import ViewIngredients from "../components/recipes/ViewIngredients.vue";
import BatchRequest from "../api/BatchRequest";

export default {
  name: "Brewing",
  data: function() {
    return {
      batch: null,
      loading: true,
    };
  },
  methods: {
    startFermenting() {
      BatchRequest.startFermenting(this.batch.id).then(() => {
        this.$router.push("/fermenting");
      });
    },
    saveIngredients() {
      BatchRequest.updateIngredients(this.batch.id, this.batch.ingredients);
    }
  },
  components: { ViewIngredients },
  mounted() {
    BatchRequest.getBrewing().then((batch) => {
      this.batch = batch;
      this.loading = false;
    });
  },
};
</script>
<style></style>
