<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    scrollable
    transition="dialog-bottom-transition"
    max-width="1200"
    persistent
  >
    <template v-slot:activator="{ on, attrs }" width="100%">
      <v-btn  color="primary" dark v-bind="attrs" v-on="on" v-on:click="resetForm" class="create-recipe">
        Create Recipe
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>New Recipe</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="saveRecipe()">
            Save
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="valid">
          <v-container>
            <!-- Recipe Info -->
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field dense
                  v-model="recipe.name"
                  label="Title"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="recipe.description"
                  name="input-7-1"
                  rows="2"
                  row-height="20"
                  label="Description"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Batch Size -->
            <v-row>
              <v-col cols="12" md="12">
                <div class="text-h6">Batch Size</div>
              </v-col>
              <v-col cols="3">
                <v-text-field dense label="Gallons" v-model="recipe.batchSize" type="number"></v-text-field>
              </v-col>
            </v-row>

            <!-- Ingredients -->
            <v-row align="center">
              <v-col cols="12" md="12">
                <div class="text-h6">Ingredients</div>
              </v-col>
            </v-row>
            <v-row v-for="(ingredient, index) in recipe.ingredients" :key="ingredient.createdAt">
              <v-col cols="1">
                <v-text-field dense label="Amount" v-model="ingredient.amount" type="number"></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field dense label="Unit" v-model="ingredient.amountUnit"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field dense label="Ingredient" v-model="ingredient.name"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field dense label="Use" v-model="ingredient.use"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field dense label="Time" v-model="ingredient.time"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field dense label="Notes" v-model="ingredient.notes"></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn icon v-on:click="removeIngredient(index)">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-btn tile color="secondary" v-on:click="addIngredient">
                <v-icon left>
                  mdi-plus
                </v-icon>
                Add Ingredient
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import RecipeRequest from '../../api/RecipeRequest';
export default {
  name: "CreateNewRecipe",
  props: [],

  data: function() {
    return {
      dialog: false,
      valid: false,
      recipe: {},
      phases: ['Brewing', 'Fermenting', 'Bottled']
    };
  },
  mounted() {},
  methods: {
      resetForm() {
        this.recipe = {
          category: 'Beer',
          ingredients: [{createdAt: 1}],
          batchSizeUnit: 'Gallons'
        }
      },
      saveRecipe() {
          this.recipe.batchSizeUnit = 'Gallons';
          RecipeRequest.create(this.recipe)
            .then(() => {
              this.dialog = false;
              this.$emit('success');
            });
      },
      addIngredient() {
          this.recipe.ingredients.push({createdAt: new Date().getTime()});
      },
      removeIngredient(index) {
        this.recipe.ingredients.splice(index, 1);
      }
  },
};
</script>
<style lang="sass" scoped>

button.create-recipe.v-btn 
  border-bottom-right-radius: 0
  border-bottom-left-radius: 0
 

</style>